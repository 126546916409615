import React, { Component } from "react";
import abfllogowide from "../assets/abfl-logo-wide.png";
import Crediwatch from "../assets/Crediwatch.png";
import {
  getConsentOtp,
  sentConsentOtp,
  getPanVarified
} from "../actions/gstAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
class GstConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pan: "",
      ispanVarified: false,
      gstId: "",
      gstIdVerified: false,
      mobileNo: "",
      mobileNoVarified: false,
      gstUserName: "",
      otp: "",
      isOtpVarified: false,
      isgstUserVarified: false,
      errorShow: false
    };
  }
  componentWillReceiveProps() {
    if (this.props.consentOtp && this.props.consentOtp.loader === false) {
      this.setState({ errorShow: true });
    } else {
      // this.setState({ errorShow: false });
    }
  }
  validatePAN = e => {
    var panPattern = "^([A-Z]){5}([0-9]){4}([A-Z]){1}?$";
    let Data = {
      pan: e.target.value.toUpperCase(),
      key: "oj9S1iyepzmWZYbeIMazfhpHslvt890"
    };
    if (e.target.value.toUpperCase().search(panPattern) === 0) {
      this.setState({ ispanVarified: true });
      this.props.getPanVarified(Data);
    } else {
      this.setState({
        ispanVarified: false
        // gstIdVerified: false,
        // isgstUserVarified: false,
        // isOtpVarified: false,
        // mobileNoVarified: false
      });
    }
    this.setState({ pan: e.target.value.toUpperCase() });
  };

  vallidateGSTIN = e => {
    let gstinregex =
      "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$";
    if (e.target.value.toUpperCase().search(gstinregex) === 0) {
      this.setState({ gstIdVerified: true });
    } else {
      this.setState({
        gstIdVerified: false
        // isgstUserVarified: false,
        // isOtpVarified: false,
        // mobileNoVarified: false
      });
    }
    this.setState({ gstId: e.target.value.toUpperCase() });
  };

  validateGSTUserName = e => {
    let gstUserNameRegex = "^[a-zA-Z][a-zA-Z0-9._-]{8,15}$";
    if (e.target.value.search(gstUserNameRegex) === 0) {
      this.setState({ isgstUserVarified: true });
    } else {
      this.setState({
        isgstUserVarified: false
        // isOtpVarified: false,
        // mobileNoVarified: false
      });
    }
    this.setState({ gstUserName: e.target.value });
  };
  validateOTP = e => {
    let otpregex = "^[0-9]{6,6}$";
    if (e.target.value.search(otpregex) === 0) {
      this.setState({ isOtpVarified: true });
    } else {
      this.setState({ isOtpVarified: false });
    }
    this.setState({ otp: e.target.value });
  };
  validateMobileNo = e => {
    let mobileregex = "^[0-9]{10,10}$";
    if (e.target.value.search(mobileregex) === 0) {
      this.setState({ mobileNoVarified: true });
    } else {
      this.setState({
        mobileNoVarified: false
        //    isOtpVarified: false
      });
    }
    this.setState({ mobileNo: e.target.value });
  };
  getOTP = () => {
    this.setState({ isOtpButtonEnable: true });
    let data = {
      pan: this.state.pan,
      gstn: this.state.gstId,
      phone_number: this.state.mobileNo,
      username: this.state.gstUserName,
      key: "oj9S1iyepzmWZYbeIMazfhpHslvt890",
      type: "gstn",
      action_type: "request_otp"
    };
    this.props.getConsentOtp(data);
  };
  submitOtp = () => {
    let data = {
      pan: this.state.pan,
      gstn: this.state.gstId,
      phone_number: this.state.mobileNo,
      username: this.state.gstUserName,
      otp: this.state.otp,
      key: "oj9S1iyepzmWZYbeIMazfhpHslvt890",
      type: "gstn",
      action_type: "submit_otp"
    };
    this.props.sentConsentOtp(data);
    // this.setState({errorShow:true})
  };

  render() {
    const { panInfo } = this.props;
    return (
      <div className="w-100 pt-5">
        <div className="card card-default w-75 mx-auto mobile-width">
          <div className="card-header">
            <h5 className="gst-modal-title">
              <img className="brand-img" src={abfllogowide} alt="ABFL" />
            </h5>
            <span className="model-heading card-heading-view">
              GST Consent {" "}
            </span>
          </div>
          <div className="card-body">
            {this.props.consentOtp &&
            this.props.consentOtp.data &&
            this.props.consentOtp.data.status === "success" ? (
              <div className="row text-center">
                <div className="success-container w-100">
                  <div
                    className={
                      this.props.consentOtp &&
                      this.props.consentOtp.data &&
                      this.props.consentOtp.data.status === "success"
                        ? "icon-container"
                        : "error-container"
                    }
                  >
                    {this.props.consentOtp &&
                    this.props.consentOtp.data &&
                    this.props.consentOtp.data.status === "success" ? (
                      <i className="icon icon-success success-icon" />
                    ) : (
                      <span className="icon icon-close error-icon" />
                    )}
                  </div>
                  {this.props.consentOtp &&
                  this.props.consentOtp.data &&
                  this.props.consentOtp.data.status === "success" ? (
                    <div className="font-weight-bold mt-3">Thank You</div>
                  ) : (
                    <div className="font-weight-bold mt-3">Oops!</div>
                  )}
                  {this.props.consentOtp &&
                  this.props.consentOtp.data &&
                  this.props.consentOtp.data.status === "success" ? (
                    <div className="mt-2">
                      {"Your Consent for GST ID-" +
                        (this.state.gstId !== ""
                          ? this.state.gstId.substring(0, 4) +
                            "XXXXXXX" +
                            this.state.gstId.substring(11, 15)
                          : "") +
                        " has been acknowledged and sent."}
                    </div>
                  ) : (
                    <div className="mt-2">
                      {" "}
                      Something went wrong. Please{" "}
                      <span>
                        <a href="/gst">try again.</a>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="">
                {this.props.consentOtp &&
                this.props.consentOtp.data &&
                this.props.consentOtp.data.status === "failed" &&
                this.props.consentOtp &&
                  this.props.consentOtp.loader === false &&
                this.state.errorShow ? (
                  <div className="alert-message bg-red">
                    <div
                      className="button-class"
                      onClick={() => {
                        this.setState({ errorShow: false });
                      }}
                    >
                      Close
                    </div>
                    {this.props.consentOtp.message
                      ? this.props.consentOtp.message
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-3 mb-4 text-center">
                  Please enter the following details
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left mobile-view">
                    <div className="pan-circle pan-filled"></div>
                    <div
                      className={
                        this.state.ispanVarified
                          ? "pan-border-filled pan-border"
                          : "pan-border"
                      }
                    ></div>
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    Enter Company PAN
                  </div>
                  <div className="col-sm-4">
                    <div className="input-container">
                      <input
                        ref={el => (this.input = el)}
                        className="form-control"
                        placeholder="PAN"
                        value={this.state.pan}
                        autoFocus
                        onFocus={() => {
                          this.setState({ panfocus: true });
                        }}
                        onBlur={() => {
                          this.setState({ panfocus: false });
                        }}
                        onChange={e => {
                          if (!(e.target.value === " ")) {
                            this.validatePAN(e);
                          }
                        }}
                      />
                      {panInfo && panInfo.status === "success" ? (
                        <i className="icon icon-success-filled input-success-icon" />
                      ) : (
                        ""
                      )}
                    </div>
                    {!this.state.ispanVarified &&
                    this.state.pan !== "" &&
                    !this.state.panfocus ? (
                      <div className="error-message">
                        Please enter valid PAN no.
                      </div>
                    ) : (
                      ""
                    )}
                    {panInfo && panInfo.status === "failed" ? (
                      <div className="error-message">
                        PAN not verified. Try again.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left  mobile-view">
                    <div
                      className={
                        this.state.gstIdVerified
                          ? "pan-filled pan-circle"
                          : "pan-circle"
                      }
                    ></div>
                    <div
                      className={
                        this.state.gstIdVerified
                          ? "pan-border-filled pan-border"
                          : "pan-border"
                      }
                    ></div>
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    Enter Company GST ID
                  </div>
                  <div className="col-sm-4">
                    <div className="input-container">
                      <input
                        className="form-control"
                        placeholder={this.state.ispanVarified ? " GST ID" : ""}
                        value={this.state.gstId}
                        onFocus={() => {
                          this.setState({ gstIdFocus: true });
                        }}
                        disabled={
                          this.state.ispanVarified &&
                          panInfo &&
                          panInfo.status === "success"
                            ? false
                            : true
                        }
                        onBlur={() => {
                          this.setState({ gstIdFocus: false });
                        }}
                        onChange={e => {
                          if (!(e.target.value === " ")) {
                            this.vallidateGSTIN(e);
                          }
                        }}
                      />
                      {panInfo &&
                      panInfo.gstns &&
                      panInfo.gstns.length > 0 &&
                      panInfo.gstns.includes(this.state.gstId) ? (
                        <i className="icon icon-success-filled input-success-icon" />
                      ) : (
                        ""
                      )}
                    </div>
                    {!this.state.gstIdVerified &&
                    this.state.gstId !== "" &&
                    !this.state.gstIdFocus ? (
                      <div className="error-message">
                        Please enter valid GSTIN
                      </div>
                    ) : (
                      ""
                    )}
                    {panInfo &&
                    panInfo.gstns &&
                    panInfo.gstns.length > 0 &&
                    this.state.gstIdVerified &&
                    !panInfo.gstns.includes(this.state.gstId) ? (
                      <div className="error-message">GSTN ID not matched</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left  mobile-view">
                    <div
                      className={
                        this.state.isgstUserVarified
                          ? "pan-filled pan-circle"
                          : "pan-circle"
                      }
                    ></div>
                    <div
                      className={
                        this.state.isgstUserVarified
                          ? "pan-border-filled pan-border"
                          : "pan-border"
                      }
                    ></div>
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    Enter Company GST User Name
                  </div>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      placeholder={
                        this.state.gstIdVerified && this.state.ispanVarified
                          ? "GST user name"
                          : ""
                      }
                      value={this.state.gstUserName}
                      disabled={
                        this.state.gstIdVerified && this.state.ispanVarified
                          ? false
                          : true
                      }
                      onFocus={() => this.setState({ gstUserFocus: true })}
                      onBlur={() => this.setState({ gstUserFocus: false })}
                      onChange={e => {
                        if (!(e.target.value === " ")) {
                          this.validateGSTUserName(e);
                        }
                      }}
                    />
                    {!this.state.isgstUserVarified &&
                    this.state.gstUserName !== "" &&
                    !this.state.gstUserFocus ? (
                      <div className="error-message">
                        Please enter valid GST username
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left  mobile-view">
                    <div
                      className={
                        this.state.mobileNoVarified
                          ? "pan-filled pan-circle"
                          : "pan-circle"
                      }
                    ></div>
                    <div
                      className={
                        this.state.mobileNoVarified
                          ? "pan-border-filled pan-border"
                          : "pan-border"
                      }
                    ></div>
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                   Company GST ID associated  Mobile No.
                  </div>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      placeholder={
                        this.state.isgstUserVarified &&
                        this.state.gstIdVerified &&
                        this.state.ispanVarified
                          ? "Enter 10 digit mobile no"
                          : ""
                      }
                      value={this.state.mobileNo}
                      disabled={
                        this.state.isgstUserVarified &&
                        this.state.gstIdVerified &&
                        this.state.ispanVarified
                          ? false
                          : true
                      }
                      onFocus={() => {
                        this.setState({ mobileFocus: true });
                      }}
                      onBlur={() => {
                        this.setState({ mobileFocus: false });
                      }}
                      onChange={e => {
                        if (!(e.target.value === " ")) {
                          if (Number.isInteger(Number(e.target.value))) {
                            this.validateMobileNo(e);
                          }
                        }
                      }}
                    />
                    {!this.state.mobileNoVarified &&
                    this.state.mobileNo !== "" &&
                    !this.state.mobileFocus ? (
                      <div className="error-message">
                        Please enter valid 10 digit mobile number
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left  mobile-view">
                    <div
                      className={
                        this.state.isOtpVarified
                          ? "pan-filled pan-circle"
                          : "pan-circle"
                      }
                    ></div>
                    {/* <div className={this.state.gstUserName!==""?"pan-border-filled pan-border":"pan-border" }></div> */}
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    Enter OTP
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="password"
                      placeholder={
                        this.props.request_otp && this.props.request_otp.status
                          ? "Enter 6 digit otp"
                          : ""
                      }
                      disabled={
                        this.props.request_otp &&
                        this.props.request_otp.status === "success"
                          ? false
                          : true
                      }
                      className="form-control"
                      onFocus={() => {
                        this.setState({ otpFocus: true });
                      }}
                      onBlur={() => {
                        this.setState({ otpFocus: false });
                      }}
                      onChange={e => {
                        this.validateOTP(e);
                      }}
                    />
                    {!this.state.isOtpVarified &&
                    this.state.otp !== "" &&
                    !this.state.otpFocus ? (
                      <div className="error-message">
                        Please enter valid OTP
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 generate-button">
                    {this.state.isOtpButtonEnable ? (
                      <div className="resend-message">
                        Didn't receive the OTP?
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-primary"
                      disabled={this.state.mobileNoVarified ? false : true}
                      onClick={this.getOTP}
                    >
                      {this.state.isOtpButtonEnable
                        ? "Regenerate OTP"
                        : "Generate OTP"}
                    </button>
                  </div>
                </div>
                <div
                  className={
                    (this.props.request_otp &&
                    this.props.request_otp.status === "success"
                      ? "text-success "
                      : "text-danger ") + " w-100 text-center mt-3 "
                  }
                >
                  {this.props.request_otp && this.props.request_otp.message
                    ? this.props.request_otp.message
                    : ""}
                </div>
                <div className="row mt-5 ">
                  <div className="gst-button-container">
                    <button
                      disabled={this.state.isOtpVarified ? false : true}
                      className="btn btn-primary right-button"
                      onClick={this.submitOtp}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="card-footer text-right mobile-text-center">
            <small> Powered by </small>
            <img
              className="crediwa-logo"
              src={Crediwatch}
              alt="Crediwatch"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // console.log(state.consentOtp)
  return {
    request_otp: state.requestOtp,
    consentOtp: state.consentOtp,
    panInfo: state.PanVerification
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsentOtp,
      sentConsentOtp,
      getPanVarified
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GstConsent);
