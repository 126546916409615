const initialState = {
  loader: false,
  data: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_GST_CONSENT_LOADER":
      state = {
        ...state,
        loader: action.payload
      };
      break;
    case "SET_GST_CONSENT":
      state = {
        ...state,
        error: false,
        data: action.payload
      };
      break;
    default:
      return state;
  }
  return state;
}
