
const initialState = {
    status:""
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case "SET_PAN_VARIFIED":
        return action.payload
      default:
        return state;
    }
  }