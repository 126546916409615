import axios from "axios";

export function setRequestOtp(payload) {
  return {
    type: "SET_REQUEST_OTP",
    payload
  };
}
export function setConsentLoader(payload) {
  return {
    type: "SET_GST_CONSENT_LOADER",
    payload
  };
}
export function setConsentOtp(payload) {
  return {
    type: "SET_GST_CONSENT",
    payload
  };
}
export function setPanVarified(payload) {
  return {
    type: "SET_PAN_VARIFIED",
    payload
  };
}
export function getPanVarified(data) {
  return dispatch => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/consents/customer_verification",
      data: data,
      config: { headers: { "content-type": "application/json" } }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(setPanVarified(response.data));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(setPanVarified(error));
      });
  };
}

export function getConsentOtp(data) {
  return dispatch => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/consents/consent_auth",
      // url: 'http://thogo.abfl.crediwatch.org/private/api/v1/consents/consent_auth',
      data: data,
      config: { headers: { "content-type": "application/json" } }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(setRequestOtp(response.data));
        }
      })
      .catch(function(error) {
        dispatch(setRequestOtp(error));
      });
  };
}
export function sentConsentOtp(data) {
  return dispatch => {
    dispatch(setConsentLoader(true));
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/consents/consent_auth",
      // url: 'http://thogo.abfl.crediwatch.org/private/api/v1/consents/consent_auth' ,
      data: data,
      config: { headers: { "content-type": "application/json" } }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(setConsentLoader(false));
          dispatch(setConsentOtp(response.data));
        }
      })
      .catch(function(error) {
        dispatch(setConsentOtp("error"));
      });
  };
}
