import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import GstConsent from './Components/GstConsent';
import ItrConsent from './Components/ItrConsent';
import AadharConsent from './Components/AadharConsent';
import Home from './Components/Home'

function App() {
  return (
    <Provider store={store}>
       <Router>
        <div className="App">
        <Route exact path="/gst" component={GstConsent} />
        <Route exact path="/itr" component={ItrConsent} />
        <Route exact path="/aadhaar" component={AadharConsent} />
        <Route exact path="/" component={Home} />
          {/* <GstConsent /> */}
        </div>
       </Router>
    </Provider>
    
  );
}

export default App;
