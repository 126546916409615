import React, { Component } from "react";
import Crediwatch from "../assets/Crediwatch.png";
import abfllogowide from "../assets/abfl-logo-wide.png";

export default class Home extends Component {
  render() {
    return (
      <div className="container mt-5 pt-5">
        <div className="card">
          <div className="card-body text-center">
            <h1>
              {/* <a href={}> */}
                <img
                  src={abfllogowide}
                  alt="Crediwatch Logo"
                  className="abfl-logo"
                  style={{ width: "fit-content", height: "fit-content" }}
                />
              {/* </a> */}
            </h1>
            <h5>
              This is  the ABFL Consent portal. If you are here without proper
              link or token, then please contact your referrer.{" "} 
              {/* <a href="mailto:support@crediwatch.com">support@crediwatch.com</a> */}
            </h5>
          </div>
        </div>
      </div>
    );
  }
}
