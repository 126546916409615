import React, { Component } from "react";
import _ from "lodash";
import abfllogowide from "../assets/abfl-logo-wide.png";
import Crediwatch from "../assets/Crediwatch.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getConsentOtp, sentConsentOtp } from "../actions/gstAction";

class AadharConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pan: "",
      aadharImg: "",
      panfocus: false,
      ispanVarified: false,
      file: null,
      errorShow: false
    };
  }
  componentWillReceiveProps() {
    if (this.props.consentOtp && this.props.consentOtp.loader === false) {
      this.setState({ errorShow: true });
    } else {
      // this.setState({ errorShow: false });
    }
  }
  submitAadhar = () => {
    const fd = new FormData();
    if (this.state.file && this.state.file.name != undefined) {
      fd.append("qr_image", this.state.file, this.state.file.name);
    }
    fd.append("pan", this.state.pan);
    fd.append("key", "oj9S1iyepzmWZYbeIMazfhpHslvt890");
    fd.append("action_type", "verify_aadhar");
    fd.append("type", "aadhar");
    this.props.sentConsentOtp(fd);
  };
  validatePAN = e => {
    var panPattern = "^([A-Z]){5}([0-9]){4}([A-Z]){1}?$";
    if (e.target.value.toUpperCase().search(panPattern) === 0) {
      this.setState({ ispanVarified: true });
    } else {
      this.setState({
        ispanVarified: false
      });
    }
    this.setState({ pan: e.target.value.toUpperCase() });
  };

  render() {
    return (
      <div className="w-100 pt-5">
        <div className="card card-default w-75 mx-auto mobile-width">
          <div className="card-header">
            <h5 className="gst-modal-title">
              <img className="brand-img" src={abfllogowide} alt="ABFL" />
            </h5>
            <span className="model-heading card-heading-view">
              AADHAAR Verification{" "}
            </span>
          </div>
          <div className="card-body">
            {this.props.consentOtp &&
            this.props.consentOtp.data &&
            this.props.consentOtp.data.status === "success" ? (
              <div className="row text-center">
                <div className="success-container w-100">
                  <div
                    className={
                      this.props.consentOtp.data &&
                      this.props.consentOtp.data.status === "success"
                        ? "icon-container"
                        : "error-container"
                    }
                  >
                    {this.props.consentOtp.data &&
                    this.props.consentOtp.data.status === "success" ? (
                      <i className="icon icon-success success-icon" />
                    ) : (
                      <span className="icon icon-close error-icon" />
                    )}
                  </div>
                  {this.props.consentOtp.data &&
                  this.props.consentOtp.data.status === "success" ? (
                    <div className="font-weight-bold mt-3">Thank You</div>
                  ) : (
                    <div className="font-weight-bold mt-3">Oops!</div>
                  )}
                  {this.props.consentOtp.data &&
                  this.props.consentOtp.data.status === "success" ? (
                    <div className="mt-2">
                      {"We have received your consent for AADHAAR"}
                    </div>
                  ) : (
                    <div className="mt-2">
                      {" "}
                      Something went wrong. Please{" "}
                      <span>
                        <a href="/gst">try again.</a>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="">
                {this.props.consentOtp.data &&
                this.props.consentOtp.data.status === "failure" &&
                this.props.consentOtp &&
                  this.props.consentOtp.loader === false &&
                this.state.errorShow ? (
                  <div className="alert-message bg-red">
                    <div
                      className="button-class"
                      onClick={() => {
                        this.setState({ errorShow: false });
                      }}
                    >
                      Close
                    </div>
                    {this.props.consentOtp.data &&
                    this.props.consentOtp.data.status
                      ? this.props.consentOtp.data &&
                        this.props.consentOtp.data.status
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-3 mb-4 text-center">
                  Please enter the following details
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left mobile-view">
                    <div className="pan-circle pan-filled"></div>
                    <div
                      className={
                        this.state.ispanVarified
                          ? "pan-border-filled pan-border"
                          : "pan-border"
                      }
                    ></div>
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    PAN
                  </div>
                  <div className="col-sm-4">
                    <input
                      ref={el => (this.input = el)}
                      className="form-control"
                      placeholder=" Enter PAN"
                      value={this.state.pan}
                      autoFocus
                      onFocus={() => {
                        this.setState({ panfocus: true });
                      }}
                      onBlur={() => {
                        this.setState({ panfocus: false });
                      }}
                      onChange={e => {
                        if (!(e.target.value === " ")) {
                          this.validatePAN(e);
                        }
                      }}
                    />
                    {!this.state.ispanVarified &&
                    this.state.pan !== "" &&
                    !this.state.panfocus ? (
                      <div className="error-message">
                        Please enter valid PAN no.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 pt-3 text-left mobile-view">
                    <div
                      className={
                        this.state.file && this.state.file.name !== ""
                          ? "pan-filled pan-circle"
                          : "pan-circle"
                      }
                    ></div>
                    {/* <div className={this.state.gstUserName!==""?"pan-border-filled pan-border":"pan-border" }></div> */}
                  </div>
                  <div className="col-sm-3 pt-2 text-right mobile-text">
                    Aadhaar QR Image
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="file"
                      placeholder="upload aadhar"
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      onChange={e => {
                        this.setState({ file: e.target.files[0] });
                      }}
                    />
                    {/* <img src={this.state.aadharImg} /> */}
                  </div>
                </div>
                <div className="w-100 mt-5 text-center ">
                  <button
                    disabled={
                      this.state.ispanVarified &&
                      this.state.file &&
                      this.state.file.name !== ""
                        ? false
                        : true
                    }
                    className="btn btn-primary "
                    onClick={this.submitAadhar}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="card-footer text-right mobile-text-center">
            <small> Powered by </small>
            <img
              className="crediwa-logo"
              src={Crediwatch}
              alt="Crediwatch"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  // console.log(state.Party_Titlebar)
  return {
    request_otp: state.requestOtp,
    consentOtp: state.consentOtp
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsentOtp,
      sentConsentOtp
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AadharConsent);

// export default AadharConsent;
